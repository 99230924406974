.products .filter-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}
.products .filter-bar .select {
  background: #F8F8FF;
  border-radius: 32px;
}
.products .filter-bar .select .MuiInputBase-input {
  background: #F8F8FF;
  border-radius: 32px;
  font-size: 14px;
}
.products .search-input .MuiInputBase-input {
  padding-bottom: 0;
}
.products .percent {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.products .percent.down {
  color: #ff4e4e;
}
.products .percent.up {
  color: #10b601;
}
.products .kpi {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.products .graph {
  display: flex;
  justify-content: space-between;
}
.products .main-header {
  margin: -32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Neutral-Colors-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 16px 32px;
}
.products .main-header .page-title svg path {
  fill: #000000;
}
.products .main-header h1,
.products .main-header h2 {
  margin: 0;
}
.products .main-header .text-search svg path {
  fill: #ffffff !important;
}
.products .rating {
  overflow: hidden;
  padding: 8px 0;
}
.products .rating .item-name {
  font-weight: 600;
}
.products .uploader {
  min-width: 48%;
}
.products .uploader .dropzone {
  border-radius: 50px;
  background: rgba(151, 71, 255, 0.27);
  padding: 16px 36px;
  cursor: pointer;
  width: 100px;
}
.products .uploader .dropzone:hover {
  background: rgba(151, 71, 255, 0.5);
}
.products .uploader .zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed var(--Grey-grey-500, #9e9e9e);
  background: rgba(250, 250, 250, 0.7);
  overflow: hidden;
}
.products .uploader .zone img {
  width: 100%;
}
.products .uploader .container {
  padding: 24px 0;
}
.products .uploader .img-preview {
  padding: 24px 0;
}
.products .uploader .img-preview .img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.products .uploader .img-preview .img .icon-delete {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #d14343;
  cursor: pointer;
}
.products .uploader .img-preview .img img {
  width: 100%;
}
.products .form {
  min-width: 48%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.products .form .text-field {
  flex: 1;
  min-width: 45%;
}
.products .form .select {
  max-height: 56px;
}
.products .dialog-actions {
  padding: 24px;
  border-top: 1px solid #cdcdcd;
}
.products .step-wizard .step-header {
  padding-bottom: 24px;
}
.products .stepper {
  width: 400px;
}
.products .stepper .MuiStepConnector-line {
  border-color: #eff0f6;
  border-top-width: 6px;
  border-radius: 32px;
}
.products .stepper .Mui-completed .MuiStepConnector-lin {
  border-color: #962dff;
}
.products .mesure-parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.products .mesure-parent .hauteur {
  grid-area: 1/1/2/2;
}
.products .mesure-parent .largeur {
  grid-area: 1/2/2/3;
}
.products .mesure-parent .pont {
  grid-area: 2/2/3/3;
}
.products .mesure-parent .branche {
  grid-area: 2/1/3/2;
}
.products .mesure-parent .glass-icon {
  height: 80px;
}
.products .box {
  border-radius: 4px;
  background: #e9e7ff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.products .resume {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #cac4d0;
  min-width: 500px;
}
.products .resume .item {
  flex: 1;
}
.products .resume .item .label {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.products .resume .item .val {
  color: var(--M3-sys-light-on-surface, #1d1b20);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.products .inner-container {
  overflow: auto;
  flex: 1;
}
.products .data-grid-container {
  width: 100%;
  overflow: auto;
}
.products .data-grid-container .MuiDataGrid-root {
  min-width: 800px;
}
.products .phone-preview {
  min-width: 30%;
  width: 30%;
  padding: 0;
  overflow: auto;
  height: calc(100vh - 116px);
  position: relative;
}
.products .phone-preview .close-details-container {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
}
.products .phone-preview .btn-actions {
  position: sticky;
  left: 24px;
  right: 24px;
  bottom: 24px;
  z-index: 10;
  background: white;
}
.products .phone-preview .btn-actions button {
  flex: 1;
}
.products .phone-preview .rating-container {
  padding: 16px 0;
}
.products .phone-preview .rating-container .rating svg {
  width: 24px;
  color: #ffc700;
}
.products .phone-preview .wrapper {
  padding: 24px;
}
.products .phone-preview .price {
  color: var(--Black, #000);
  text-align: right;
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.products .phone-preview .desc {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.products .phone-preview h1 {
  margin: 0;
}
.products .phone-preview h2 {
  text-transform: uppercase;
  margin: 24px 0;
  font-size: 14px;
}
.products .phone-preview .box {
  padding: 16px 0;
  gap: 0;
}
.products .phone-preview .box svg {
  width: 56px;
  height: 40px;
}
.products .phone-preview .subtitle {
  color: var(--Black, #aaaaaa);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.products .phone-preview .ref {
  color: #765dff;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.products .phone-preview .carousel-preview {
  height: 256.265px;
  overflow: hidden;
}
.products .phone-preview .carousel-preview img {
  width: 100%;
}
.products .phone-preview .items {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 24px 0;
  border-top: 1px solid rgba(204, 170, 204, 0.2666666667);
  border-bottom: 1px solid #cac4d0;
  gap: 16px;
}
.products .phone-preview .items .item {
  display: flex;
  align-items: center;
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.products .phone-preview .items .item .label {
  flex: 1;
}
.products .phone-preview .items .item .price {
  min-width: 92px;
  text-align: right;
}
.products .phone-preview .social-container {
  min-height: 40px;
  align-items: center;
  margin-bottom: 16px;
}
.products .phone-preview .social {
  display: flex;
  align-items: center;
  gap: 24px;
}
.products .phone-preview .metrics {
  color: var(--Black, #000);
  text-align: center;
  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.products .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 40px;
}
.products .disponibility {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}
.products .disponibility .left {
  background: #20C997;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
}
.products .disponibility .left .day {
  background: #FFD233;
  padding: 4px 8px;
  border-radius: 4px;
  color: black;
  font-size: 12px;
}
.products .disponibility .left.no {
  background: #ff4e4e;
}