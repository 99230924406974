.orders .filter-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}
.orders .filter-bar .select {
  background: #F8F8FF;
  border-radius: 32px;
}
.orders .filter-bar .select .MuiInputBase-input {
  background: #F8F8FF;
  border-radius: 32px;
  font-size: 14px;
}
.orders .search-input .MuiInputBase-input {
  padding-bottom: 0;
}
.orders .data-grid-container {
  width: 100%;
  overflow: auto;
}
.orders .data-grid-container .MuiDataGrid-root {
  min-width: 800px;
}
.orders .percent {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.orders .percent.down {
  color: #ff4e4e;
}
.orders .percent.up {
  color: #10b601;
}
.orders .kpi {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.orders .graph {
  display: flex;
  justify-content: space-between;
}
.orders .main-header {
  margin: -32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Neutral-Colors-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 16px 32px;
}
.orders .main-header .page-title svg path {
  fill: #000000;
}
.orders .main-header svg path {
  fill: #ffffff;
}
.orders .main-header svg {
  margin-right: 16px;
}
.orders .main-header h1,
.orders .main-header h2 {
  margin: 0;
}
.orders .rating {
  overflow: hidden;
  padding: 8px 0;
}
.orders .rating .item-name {
  font-weight: 600;
}
.orders .uploader {
  min-width: 500px;
}
.orders .uploader .dropzone {
  border-radius: 50px;
  background: rgba(151, 71, 255, 0.27);
  padding: 16px 36px;
  cursor: pointer;
  width: 100px;
}
.orders .uploader .dropzone:hover {
  background: rgba(151, 71, 255, 0.5);
}
.orders .uploader .zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed var(--Grey-grey-500, #9e9e9e);
  background: rgba(250, 250, 250, 0.7);
  overflow: hidden;
}
.orders .uploader .zone img {
  width: 100%;
}
.orders .uploader .container {
  padding: 24px 0;
}
.orders .uploader .img-preview {
  padding: 24px 0;
}
.orders .uploader .img-preview .img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.orders .uploader .img-preview .img .icon-delete {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #d14343;
  cursor: pointer;
}
.orders .uploader .img-preview .img img {
  width: 100%;
}
.orders .phone-preview {
  min-width: 30%;
  width: 30%;
  padding: 24px;
}
.orders .phone-preview .close-details-container {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 10;
}
.orders .phone-preview .notif {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.orders .phone-preview .notif .state {
  padding: 8px 16px;
  border-radius: var(--Corner-100, 4px);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}
.orders .phone-preview .notif .state.waiting {
  background-color: #ffc700;
}
.orders .phone-preview .notif .state.processing {
  background-color: #e393ff;
}
.orders .phone-preview .notif .state.shipping {
  background-color: #ff7121;
}
.orders .phone-preview .notif .state.done {
  background-color: #20c997;
}
.orders .phone-preview h2 {
  margin: 24px 0;
}
.orders .phone-preview .order-details {
  display: flex;
}
.orders .phone-preview .order-details .id {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.orders .phone-preview .order-details .opticien {
  color: #765dff;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.orders .phone-preview .order-details .date {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.orders .phone-preview .totals {
  display: flex;
  align-items: center;
  font-size: 24px;
}
.orders .phone-preview .totals .total {
  font-weight: 700;
}
.orders .phone-preview .items {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 24px 0;
  border-top: 1px solid rgba(204, 170, 204, 0.2666666667);
  border-bottom: 1px solid #cac4d0;
  gap: 8px;
}
.orders .phone-preview .items .item {
  display: flex;
  align-items: center;
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.orders .phone-preview .items .item .label {
  flex: 1;
}
.orders .phone-preview .items .item .price {
  min-width: 92px;
  text-align: right;
}
.orders .phone-preview .btn-actions {
  padding: 24px 0;
}
.orders .flow-container {
  overflow: auto;
  flex: 1;
}
.orders .flow-container h2 {
  margin-bottom: 32px;
}
.orders .flow-container .flow {
  margin-bottom: 24px;
}
.orders .flow-container .stepper {
  width: auto;
}
.orders .flow-container .stepper .event-date {
  font-size: 14px;
  color: #35353A;
  text-align: center;
}
.orders .no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 40px;
}