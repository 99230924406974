.banners .data-grid-container {
  width: 100%;
  overflow: auto;
}
.banners .data-grid-container .MuiDataGrid-root {
  min-width: 800px;
}
.banners .percent {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.banners .percent.down {
  color: #ff4e4e;
}
.banners .percent.up {
  color: #10b601;
}
.banners .kpi {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.banners .graph {
  display: flex;
  justify-content: space-between;
}
.banners .main-header {
  margin: -32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Neutral-Colors-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 16px 32px;
}
.banners .main-header .page-title svg path {
  fill: #000000;
}
.banners .main-header svg path {
  fill: #ffffff;
}
.banners .main-header svg {
  margin-right: 16px;
}
.banners .main-header h1,
.banners .main-header h2 {
  margin: 0;
}
.banners .rating {
  overflow: hidden;
  padding: 8px 0;
}
.banners .rating .item-name {
  font-weight: 600;
}
.banners .uploader {
  min-width: 500px;
}
.banners .uploader .dropzone {
  border-radius: 50px;
  background: rgba(151, 71, 255, 0.27);
  padding: 16px 36px;
  cursor: pointer;
  width: 100px;
}
.banners .uploader .dropzone:hover {
  background: rgba(151, 71, 255, 0.5);
}
.banners .uploader .zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed var(--Grey-grey-500, #9e9e9e);
  background: rgba(250, 250, 250, 0.7);
  overflow: hidden;
}
.banners .uploader .zone img {
  width: 100%;
}
.banners .uploader .container {
  padding: 24px 0;
}
.banners .uploader .img-preview {
  padding: 24px 0;
}
.banners .uploader .img-preview .img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.banners .uploader .img-preview .img .icon-delete {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #d14343;
  cursor: pointer;
}
.banners .uploader .img-preview .img img {
  width: 100%;
}
.banners .phone-preview {
  min-width: 30%;
  width: 30%;
  padding: 0;
  overflow: hidden;
}
.banners .phone-preview .wrapper {
  padding: 24px;
}
.banners .phone-preview .carousel-preview {
  height: 256.265px;
  overflow: hidden;
}
.banners .phone-preview .carousel-preview img {
  width: 100%;
}
.banners .phone-preview .placeholder {
  height: 40px;
  border-radius: 6px;
  background: #dee8ed;
  flex: 1;
}
.banners .phone-preview .placeholder.square {
  height: 120px;
}
.banners .phone-preview .mobile-header {
  background: var(--Black, #000);
  height: 72px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.banners .phone-preview h2 {
  margin: 24px 0;
}
.banners .phone-preview .order-details {
  display: flex;
}
.banners .phone-preview .order-details .id {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.banners .phone-preview .order-details .opticien {
  color: #765dff;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.banners .phone-preview .order-details .date {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.banners .phone-preview .totals {
  display: flex;
  align-items: center;
  font-size: 24px;
}
.banners .phone-preview .totals .total {
  font-weight: 700;
}
.banners .phone-preview .items {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 24px 0;
  border-top: 1px solid rgba(204, 170, 204, 0.2666666667);
  border-bottom: 1px solid #cac4d0;
  gap: 8px;
}
.banners .phone-preview .items .item {
  display: flex;
  align-items: center;
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.banners .phone-preview .items .item .label {
  flex: 1;
}
.banners .phone-preview .items .item .price {
  min-width: 92px;
  text-align: right;
}
.banners .phone-preview .btn-actions {
  padding: 24px 0;
}
.banners .flow-container {
  overflow: auto;
  flex: 1;
}
.banners .flow-container .options {
  margin: 24px 0;
}
.banners .flow-container .MuiFormControlLabel-label {
  color: var(--Black, #000);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.banners .flow-container h2 {
  margin-bottom: 16px;
}
.banners .flow-container .flow {
  margin-bottom: 24px;
}
.banners .flow-container .stepper {
  width: auto;
}
.banners .flow-container .stepper .event-date {
  font-size: 14px;
  color: #35353a;
  text-align: center;
}
.banners .img-preview {
  padding: 24px 0;
}
.banners .img-preview .img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}
.banners .img-preview .img .icon-delete {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #d14343;
  cursor: pointer;
}
.banners .img-preview .img img {
  width: 100%;
}