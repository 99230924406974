.settings .main-header {
  margin: -32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Neutral-Colors-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 16px 32px;
}
.settings .main-header .page-title svg path {
  fill: #000000;
}
.settings .main-header svg path {
  fill: #ffffff;
}
.settings .main-header svg {
  margin-right: 16px;
}
.settings .main-header h1,
.settings .main-header h2 {
  margin: 0;
}
.settings .wrapper {
  padding: 24px 0;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 24px;
}
.settings .wrapper h2 {
  margin-bottom: 32px;
}