.container {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(112deg, #E9E7FF 0%, #962DFF 50%, #4A3AFF 100%);
    justify-content: center;
    align-items: center;
    display: flex;

    .logo{
    position: absolute;
    left:50%;
    top: -74px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height:132px;
    border-radius: 40px;
    background: #000000;
    
}

    
    .h1{
        color: var(--Black, #000);
        text-align: center;
        font-family: Barlow;
        font-size: 38px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .content {
        padding-top: 80px !important;
        width: 40%;
        padding: 32px;
        flex-shrink: 0;
        border-radius: 40px;
        background: #FFF;
        color: #000000;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    
}

.btn{
    background-color: red;
    border-radius: 40px;
}








.white-bg {
    width: 586px;
    height: 573px;
    left: 0px;
    top: 66px;
    position: absolute;
    background: white;
    border-radius: 40px;
}

.forgot-password {
    width: 131px;
    height: 17px;
    left: 223px;
    top: 558px;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 23px;
    display: inline-flex;
}

.forgot-password-text {
    color: black;
    font-size: 14px;
    font-family: Barlow;
    font-weight: 600;
    word-wrap: break-word;
}

.sign-in-text {
    left: 264px;
    top: 496px;
    position: absolute;
    color: white;
    font-size: 20px;
    font-family: Barlow;
    font-weight: 700;
    word-wrap: break-word;
}

/* ... Ajoutez les autres classes CSS de la même manière */
