.sidebar {
  
  height: 100vh;
  flex-shrink: 0;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.collapsed{

    .logo{
      overflow: hidden;
    width: 24px;
    align-self: center;
    }
    .menu .item .label, .user .profile .label, .user .logout{
      display: none;
    }
  }

  .logo {
    padding: 40px 0;
    text-align: center;
    svg{
      width: 160px;
    }
  }

  .menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 32px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      .icon svg {
          height: 16px;
        }

      &:hover {
        background-color: #2d2d2d;

        .icon {
          animation: squeez 0.4s ease-in;
        }
      }
      &.active {
        background-color: #2d2d2d;
      }

      .icon {
      }
      .label {
        color: #fff;
        font-family: Barlow;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  @keyframes squeez {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  .user {
    height: 84px;
    display: flex;
    align-items: center;
    padding: 0 24px;

    .logout {
      min-width: 32px;
    }

    .profile {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;

      .initial {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-color: #8338ec;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .label {
        color: #fff;
        font-family: Barlow;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
