.settings {
  .main-header {
    margin: -32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Neutral-Colors-White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    padding: 16px 32px;

    .page-title svg path {
      fill: #000000;
    }
    svg path {
      fill: #ffffff;
    }
    svg {
      margin-right: 16px;
    }

    h1,
    h2 {
      margin: 0;
    }
  }

  .wrapper {
    padding: 24px 0;
    border-bottom: 1px solid #aaaaaa;
    margin-bottom: 24px;

    h2 {
      margin-bottom: 32px;
    }
  }
}
