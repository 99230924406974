@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: Barlow;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.page {
  width: 100vw;
  height: 100vh;
  background: #E9E7FF;
}
.page .content {
  padding: 24px;
  height: 100vh;
  overflow: auto;
  position: relative;
}

.divider-16 {
  min-height: 16px;
  height: 16px;
}

.divider-24 {
  min-height: 24px;
  height: 24px;
}

.divider-32 {
  min-height: 32px;
  height: 32px;
}

.divider-40 {
  min-height: 40px;
  height: 40px;
}

.divider-48 {
  min-height: 48px;
  height: 48px;
}

.divider-56 {
  min-height: 56px;
  height: 56px;
}

.divider-64 {
  min-height: 64px;
  height: 64px;
}

.row {
  display: flex;
}
.row.wrap {
  flex-wrap: wrap;
}
.row.center {
  align-items: center;
}
.row.between {
  justify-content: space-between;
}
.row.gap-4 {
  gap: 4px;
}
.row.gap-8 {
  gap: 8px;
}
.row.gap-16 {
  gap: 16px;
}
.row.gap-24 {
  gap: 24px;
}
.row.gap-32 {
  gap: 32px;
}

.column {
  display: flex;
  flex-direction: column;
}
.column.gap-4 {
  gap: 4px;
}
.column.gap-8 {
  gap: 8px;
}
.column.gap-16 {
  gap: 16px;
}
.column.gap-24 {
  gap: 24px;
}
.column.gap-32 {
  gap: 32px;
}

.flex {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

h1 {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 32px 0;
}

h2 {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.subtitle {
  color: #7F7F7F;
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bloc {
  border-radius: 20px;
  background: var(--Neutral-Colors-White, #FFF);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 24px;
  position: relative;
}
.bloc .header {
  border-bottom: 1px solid #E5E5EF;
  padding-bottom: 24px;
}

p-0 {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.p-h-0 {
  padding-left: 0;
  padding-right: 0;
}

.centered-content {
  display: flex;
  align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}