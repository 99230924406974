.orders {
  
  .filter-bar{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    
    
    .select{
      
      background: #F8F8FF;
      border-radius: 32px;
      
      
      & .MuiInputBase-input{
        background: #F8F8FF;
        border-radius: 32px;
        font-size: 14px;
        
        
        
      }
    }
  }
  
  .search-input{
    
    .MuiInputBase-input{
      padding-bottom: 0;
      
    }
  }
  
  .data-grid-container {
    width: 100%;
    overflow: auto;
    
    .MuiDataGrid-root {
      min-width: 800px;
    }
  }
  .percent {
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
    &.down {
      color: #ff4e4e;
    }
    
    &.up {
      color: #10b601;
    }
  }
  
  .kpi {
    color: var(--Black, #000);
    font-family: Barlow;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .graph {
    display: flex;
    justify-content: space-between;
  }
  
  .main-header {
    margin: -32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Neutral-Colors-White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    padding: 16px 32px;
    
    .page-title svg path {
      fill: #000000;
    }
    svg path {
      fill: #ffffff;
    }
    svg {
      margin-right: 16px;
    }
    
    h1,
    h2 {
      margin: 0;
    }
  }
  
  .rating {
    overflow: hidden;
    padding: 8px 0;
    .item-name {
      font-weight: 600;
    }
    .stars {
    }
  }
  
  .uploader {
    min-width: 500px;
    
    .dropzone {
      border-radius: 50px;
      background: rgba(151, 71, 255, 0.27);
      padding: 16px 36px;
      cursor: pointer;
      width: 100px;
      
      &:hover {
        background: rgba(151, 71, 255, 0.5);
      }
    }
    .zone {
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px dashed var(--Grey-grey-500, #9e9e9e);
      background: rgba(250, 250, 250, 0.7);
      overflow: hidden;
      
      img {
        width: 100%;
      }
    }
    
    .container {
      padding: 24px 0;
    }
    
    .img-preview {
      padding: 24px 0;
      
      .img {
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        
        .icon-delete {
          position: absolute;
          right: 16px;
          top: 16px;
          color: #d14343;
          cursor: pointer;
        }
        
        img {
          width: 100%;
        }
      }
    }
  }
  
  .phone-preview {
    min-width: 30%;
    width: 30%;
    padding: 24px;

    .close-details-container{
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 10;
    }
    
    .notif {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
      .state {
        padding: 8px 16px;
        border-radius: var(--Corner-100, 4px);
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        
        &.waiting {
          background-color: #ffc700;
        }
        &.processing {
          background-color: #e393ff;
        }
        &.shipping {
          background-color: #ff7121;
        }
        &.done {
          background-color: #20c997;
        }
      }
    }
    
    h2 {
      margin: 24px 0;
    }
    
    .order-details {
      display: flex;
      
      .id {
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .opticien {
        color: #765dff;
        font-family: Barlow;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .date {
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .totals {
      display: flex;
      align-items: center;
      font-size: 24px;
      
      .total {
        font-weight: 700;
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      padding: 24px 0;
      margin: 24px 0;
      border-top: 1px solid #cac4;
      border-bottom: 1px solid #cac4d0;
      gap: 8px;
      
      .item {
        display: flex;
        align-items: center;
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        
        .label {
          flex: 1;
        }
        .price {
          min-width: 92px;
          text-align: right;
        }
      }
    }
    .btn-actions {
      padding: 24px 0;
    }
  }
  
  .flow-container {
    overflow: auto;
    flex: 1;
    
    h2 {
      margin-bottom: 32px;
    }
    
    .flow {
      margin-bottom: 24px;
    }
    
    .stepper{
      width: auto;
      
      
      
      .event-date{
        font-size: 14px;
        color: #35353A;
        text-align: center;
      }
    }
    
    
    
  }
  
  .no-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding: 40px;
  }
}
