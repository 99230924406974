.product-dialog .percent {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product-dialog .percent.down {
  color: #ff4e4e;
}
.product-dialog .percent.up {
  color: #10b601;
}
.product-dialog .kpi {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.product-dialog .graph {
  display: flex;
  justify-content: space-between;
}
.product-dialog .main-header {
  margin: -32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Neutral-Colors-White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 16px 32px;
}
.product-dialog .main-header .page-title svg path {
  fill: #000000;
}
.product-dialog .main-header h1,
.product-dialog .main-header h2 {
  margin: 0;
}
.product-dialog .main-header .text-search svg path {
  fill: #ffffff !important;
}
.product-dialog .rating {
  overflow: hidden;
  padding: 8px 0;
}
.product-dialog .rating .item-name {
  font-weight: 600;
}
.product-dialog .uploader {
  min-width: 48%;
}
.product-dialog .uploader .dropzone {
  border-radius: 50px;
  background: rgba(151, 71, 255, 0.27);
  padding: 16px 36px;
  cursor: pointer;
  width: 100px;
}
.product-dialog .uploader .dropzone:hover {
  background: rgba(151, 71, 255, 0.5);
}
.product-dialog .uploader .zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px dashed var(--Grey-grey-500, #9e9e9e);
  background: rgba(250, 250, 250, 0.7);
  overflow: hidden;
}
.product-dialog .uploader .zone img {
  width: 100%;
}
.product-dialog .uploader .container {
  padding: 24px 0;
}
.product-dialog .uploader .img-preview {
  padding: 24px 0;
}
.product-dialog .uploader .img-preview .img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  max-width: 150px;
}
.product-dialog .uploader .img-preview .img .icon-delete {
  position: absolute;
  right: 16px;
  top: 16px;
  color: #d14343;
  cursor: pointer;
}
.product-dialog .uploader .img-preview .img img {
  width: 100%;
}
.product-dialog .form {
  min-width: 48%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.product-dialog .form .text-field {
  flex: 1;
  min-width: 45%;
}
.product-dialog .form .select {
  max-height: 56px;
}
.product-dialog .dialog-actions {
  padding: 24px;
  border-top: 1px solid #cdcdcd;
}
.product-dialog .step-wizard .step-header {
  padding-bottom: 24px;
}
.product-dialog .stepper {
  width: 400px;
}
.product-dialog .stepper .MuiStepConnector-line {
  border-color: #eff0f6;
  border-top-width: 6px;
  border-radius: 32px;
}
.product-dialog .stepper .Mui-completed .MuiStepConnector-lin {
  border-color: #962dff;
}
.product-dialog .mesure-parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.product-dialog .mesure-parent .hauteur {
  grid-area: 1/1/2/2;
}
.product-dialog .mesure-parent .largeur {
  grid-area: 1/2/2/3;
}
.product-dialog .mesure-parent .pont {
  grid-area: 2/2/3/3;
}
.product-dialog .mesure-parent .branche {
  grid-area: 2/1/3/2;
}
.product-dialog .mesure-parent .glass-icon {
  height: 80px;
}
.product-dialog .box {
  border-radius: 4px;
  background: #e9e7ff;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.product-dialog .resume {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #cac4d0;
  min-width: 48%;
}
.product-dialog .resume .item {
  flex: 1;
}
.product-dialog .resume .item .label {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.product-dialog .resume .item .val {
  color: var(--M3-sys-light-on-surface, #1d1b20);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}