.product-dialog {
  .percent {
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.down {
      color: #ff4e4e;
    }

    &.up {
      color: #10b601;
    }
  }

  .kpi {
    color: var(--Black, #000);
    font-family: Barlow;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .graph {
    display: flex;
    justify-content: space-between;
  }

  .main-header {
    margin: -32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Neutral-Colors-White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    padding: 16px 32px;

    .page-title svg path {
      fill: #000000;
    }

    h1,
    h2 {
      margin: 0;
    }

    .text-search svg path {
      fill: #ffffff !important;
    }
  }

  .rating {
    overflow: hidden;
    padding: 8px 0;
    .item-name {
      font-weight: 600;
    }
    .stars {
    }
  }

  .uploader {
    min-width: 48%;

    .dropzone {
      border-radius: 50px;
      background: rgba(151, 71, 255, 0.27);
      padding: 16px 36px;
      cursor: pointer;
      width: 100px;

      &:hover {
        background: rgba(151, 71, 255, 0.5);
      }
    }
    .zone {
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px dashed var(--Grey-grey-500, #9e9e9e);
      background: rgba(250, 250, 250, 0.7);
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .container {
      padding: 24px 0;
    }

    .img-preview {
      padding: 24px 0;

      .img {
        overflow: hidden;
        border-radius: 10px;
        position: relative;
        max-width: 150px;

        .icon-delete {
          position: absolute;
          right: 16px;
          top: 16px;
          color: #d14343;
          cursor: pointer;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .form {
    min-width: 48%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .text-field {
      flex: 1;
      min-width: 45%;
    }

    .select {
      max-height: 56px;
    }
  }
  .dialog-actions {
    padding: 24px;
    border-top: 1px solid #cdcdcd;

    .btn-actions {
    }
  }

  .step-wizard {
    .step-header {
      padding-bottom: 24px;
    }

    .step {
    }
  }

  .stepper {
    width: 400px;

    .MuiStepConnector-line {
      border-color: #eff0f6;
      border-top-width: 6px;
      border-radius: 32px;
    }

    .Mui-completed .MuiStepConnector-lin {
      border-color: #962dff;
    }
  }

  .mesure-parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    .hauteur {
      grid-area: 1 / 1 / 2 / 2;
    }
    .largeur {
      grid-area: 1 / 2 / 2 / 3;
    }
    .pont {
      grid-area: 2 / 2 / 3 / 3;
    }
    .branche {
      grid-area: 2 / 1 / 3 / 2;
    }

    .glass-icon {
      height: 80px;
    }
  }
  .box {
    border-radius: 4px;
    background: #e9e7ff;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .resume {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #cac4d0;
    min-width: 48%;

    .item {
      flex: 1;

      .label {
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      .val {
        color: var(--M3-sys-light-on-surface, #1d1b20);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }
  }
}
