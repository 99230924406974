.percent {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
}
.percent.down {
  color: #ff4e4e;
}
.percent.up {
  color: #10b601;
}

.kpi {
  color: var(--Black, #000);
  font-family: Barlow;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-width: 110px;
}

.graph {
  display: flex;
}
.graph .line-container {
  width: 100%;
}
.graph .line-container path {
  transform: translateX(calc(100% - 100px));
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart .center-label {
  color: var(--Neutral-Colors-Black, #1e1b39);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 50% */
}
.chart .chart-label-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chart .total {
  color: #9291a5;
  text-align: center;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.time-selector {
  margin: 16px 0 32px 0;
  height: 48px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  background: var(--Neutral-Colors-200, #f8f8ff);
  gap: 24px;
}
.time-selector .element {
  display: flex;
  padding: 10px 15px 11px 15px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 14px;
  color: var(--Neutral-Colors-400, #9291a5);
}
.time-selector .element.selected {
  background: #000000;
  color: #ffffff;
  font-weight: 600;
}