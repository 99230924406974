.banners {
  .data-grid-container {
    width: 100%;
    overflow: auto;

    .MuiDataGrid-root {
      min-width: 800px;
    }
  }
  .percent {
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.down {
      color: #ff4e4e;
    }

    &.up {
      color: #10b601;
    }
  }

  .kpi {
    color: var(--Black, #000);
    font-family: Barlow;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .graph {
    display: flex;
    justify-content: space-between;
  }

  .main-header {
    margin: -32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--Neutral-Colors-White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
    padding: 16px 32px;

    .page-title svg path {
      fill: #000000;
    }
    svg path {
      fill: #ffffff;
    }
    svg {
      margin-right: 16px;
    }

    h1,
    h2 {
      margin: 0;
    }
  }

  .rating {
    overflow: hidden;
    padding: 8px 0;
    .item-name {
      font-weight: 600;
    }
    .stars {
    }
  }

  .uploader {
    min-width: 500px;

    .dropzone {
      border-radius: 50px;
      background: rgba(151, 71, 255, 0.27);
      padding: 16px 36px;
      cursor: pointer;
      width: 100px;

      &:hover {
        background: rgba(151, 71, 255, 0.5);
      }
    }
    .zone {
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px dashed var(--Grey-grey-500, #9e9e9e);
      background: rgba(250, 250, 250, 0.7);
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .container {
      padding: 24px 0;
    }

    .img-preview {
      padding: 24px 0;

      .img {
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        .icon-delete {
          position: absolute;
          right: 16px;
          top: 16px;
          color: #d14343;
          cursor: pointer;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .phone-preview {
    min-width: 30%;
    width: 30%;
    padding: 0;
    overflow: hidden;

    .wrapper {
      padding: 24px;
    }

    .carousel-preview {
      height: 256.265px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .placeholder {
      height: 40px;
      border-radius: 6px;
      background: #dee8ed;
      flex: 1;

      &.square {
        height: 120px;
      }
    }

    .mobile-header {
      background: var(--Black, #000);
      height: 72px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
    }

    h2 {
      margin: 24px 0;
    }

    .order-details {
      display: flex;

      .id {
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .opticien {
        color: #765dff;
        font-family: Barlow;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .date {
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .totals {
      display: flex;
      align-items: center;
      font-size: 24px;

      .total {
        font-weight: 700;
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      padding: 24px 0;
      margin: 24px 0;
      border-top: 1px solid #cac4;
      border-bottom: 1px solid #cac4d0;
      gap: 8px;

      .item {
        display: flex;
        align-items: center;
        color: var(--Black, #000);
        font-family: Barlow;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;

        .label {
          flex: 1;
        }
        .price {
          min-width: 92px;
          text-align: right;
        }
      }
    }
    .btn-actions {
      padding: 24px 0;
    }
  }

  .flow-container {
    overflow: auto;
    flex: 1;

    .options {
      margin: 24px 0;
    }

    .MuiFormControlLabel-label {
      color: var(--Black, #000);

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }

    h2 {
      margin-bottom: 16px;
    }

    .flow {
      margin-bottom: 24px;
    }

    .stepper {
      width: auto;

      .event-date {
        font-size: 14px;
        color: #35353a;
        text-align: center;
      }
    }
  }

  .img-preview {
    padding: 24px 0;

    .img {
      overflow: hidden;
      border-radius: 10px;
      position: relative;

      .icon-delete {
        position: absolute;
        right: 16px;
        top: 16px;
        color: #d14343;
        cursor: pointer;
      }

      img {
        width: 100%;
      }
    }
  }
}
